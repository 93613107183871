import React from 'react';
import {
  Accordion,
  Column,
  Container,
  Layout,
  LayoutBlock,
  Hero,
  Title,
  Description,
  Row,
  Section,
  VideoPlayer,
  Actions
} from '@dragonchain-dev/dragon-kit';
import { Layout as PageLayout, SEO, TypeForm, FeatureRow } from '../../components/_index';

import Eye from '../../assets/images/icons/eye.svg';
import Money from '../../assets/images/icons/money.svg';
import Website from '../../assets/images/icons/website.svg';
import Flexible from '../../assets/images/icons/flexible.svg';
import Touch from '../../assets/images/icons/touch.svg';
import RewardB from '../../assets/images/icons/reward-b.svg';

const DataIntegration = () => {
  return (
    <PageLayout>
      <SEO
        title="Real world data integration solutions are compatible with blockchain technology"
        description="Integrate real world data from IoT devices, sensors or any proprietary supply chain software with your blockchain applications through oracles, APIs, and smart contracts. "
      />
      <Layout dark background="blue-dark">
        <Hero type="code">
          <div className="caption">Data Integration</div>
          <Title color="white">Business Data Integration With Blockchain Technology</Title>
          <Description color="blue-light">
            Integrate real world data from IoT devices, sensors or any proprietary supply chain software with
            your blockchain applications through oracles, APIs, and smart contracts.
          </Description>
        </Hero>
      </Layout>

      <LayoutBlock separator>
        <Container>
          <Row gap="small">
            <Column span={6}>
              <Section title="Features" />
            </Column>
          </Row>
          <FeatureRow
            features={[
              {
                Icon: Website,
                title: 'Smart Contracts',
                body: 'Streamline operations and reduce redundancy with smart contract automation.',
              },
              {
                Icon: Flexible,
                title: 'Data Integration',
                body:
                  'Allow network to network and interdepartmental communications with Interchain and interoperability.',
              },
              {
                Icon: Money,
                title: 'Traceability & Legal Proof',
                body:
                  'Whether it is a chocolate supply chain, or car insurance issue, provide full traceability and immutable legal proof of events',
              },
              {
                Icon: Eye,
                title: 'Transparency',
                body:
                  'Landing pages not only offer consumers more transparency, it is also an effective additional consumer bonding place to share your brands story and values.',
              },
              {
                Icon: RewardB,
                title: 'Loyalty',
                body:
                  'Optionally integrate your loyalty & rewards systems with your products, let consumers tip farmers, or invent something new with us.',
              },
              {
                Icon: Touch,
                title: 'Engagement',
                body:
                  'Increase consumer engagement by allowing them to scan a QR code redirecting them to an interactive, educational, and gamified experience.',
              },
            ]}
          />
          <Row auto span={12} gap="large">
            <Column>
              <Section caption="Knowledge Perk" title="How the coffee industry leverages blockchain">
                <p>
                  How does a coffee company use these technologies to innovate and provide unique consumer
                  experiences? In just 4 days the solutions team from Dragonchain had a real working
                  blockchain application up and running, ready to be tested in the store, available for all
                  their customers.
                </p>
                <br />
                <p>
                  Knowledge Perk wants to be the first to provide transparency in the coffee industry by
                  labeling each bag of coffee with a unique QR code, proving to the customer that the recipe
                  matches the roast profile on the bag. The roast data is obtained through IoT devices in the
                  roaster, and then stored on the blockchain.
                </p>
              </Section>
            </Column>
            {/* TODO: this needs to be in a column */}
            <VideoPlayer
              url="https://www.youtube.com/watch?v=85Kbf6tMUl0"
              caption="Ryan Sanderson, co-founder and CEO of Knowledge Perk"
            />
          </Row>
          <Row gap="large" /> {/* TODO: remove after video player fix */}
          <Row gap="large">
            <Column span={12}>
              <Accordion title="Frequently Asked Questions">
                <Accordion.Item title="Sensors, IoT devices, supply chains and other solutions require a large volume of transactions. Can Dragonchain handle this, while remaining affordable?">
                  Dragonchain enables you to run multiple systems to support hundreds of millions or even
                  billions of transactions per day, with our lowest fee being $0.0000025 per transaction.
                  There is no more scalable and affordable alternative without sacrificing security,
                  flexibility, and ease of development.
                </Accordion.Item>
              </Accordion>
            </Column>
          </Row>
          <Row>
            <Column span={7}>
              <Section title="Connect logic to blockchain today.">
                83% of Executives are looking for blockchain solutions to the most pressing issues facing
                their businesses. Let Dragonchain show you the private and secure, scalable and cost effective
                solutions and services we have ready for you today.
              </Section>
            </Column>
            <Column right middle span={5}>
            <Actions
            actions={[
              {
                type: 'link',
                name: 'Contact Us',
                color: 'blue',
                hover: 'blue-dark',
                to: '/contact-us',
              },
            ]}
          />
            </Column>
          </Row>
        </Container>
      </LayoutBlock>
    </PageLayout>
  );
};

export default DataIntegration;
